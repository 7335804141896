@import ../../../styles/helpers

.head
    display: flex
    margin-bottom: 112px
    +d
        margin-bottom: 80px
    +m
        flex-direction: column-reverse
        margin-bottom: 64px
    .title
        max-width: 920px
        margin-right: auto
    .button
        margin-left: 30px
        +m
            margin: 0 0 16px

.row
    display: flex
    align-items: center
    +t
        flex-direction: column-reverse

.col
    &:first-child
        flex: 0 0 256px
        margin-right: auto
        +d
            margin-right: 80px
        +t
            max-width: 400px
            margin: 0 auto
    &:nth-child(2)
        flex: 0 0 640px
        +d
            flex: 1 1 auto
        +t
            flex: 0 0 auto
            margin-bottom: 64px
        +m
            margin-bottom: 48px
        .title
            margin-bottom: 32px
        .preview
            margin-bottom: 80px
            +m
                margin-bottom: 48px

.preview
    img
        width: 100%
        border-radius: 16px

.info
    margin-bottom: 84px
    +body-1
    color: $neutrals4
    +m
        margin-bottom: 48px
        font-size: 16px
        line-height: 1.5

.content
    color: $neutrals4
    p
        &:not(:last-child)
            margin-bottom: 24px

